import React from 'react';
import i18n from '../../../../locale';
import SectionWrapper from '../../../../components/SectionWrapper';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <SectionWrapper className={styles.container}>
    <Typography variant='h3'
      weight='bold'
      className={styles.titleContainer}>
      {i18n('Vehicle care for dealerships')}
    </Typography>
    <Typography variant='subtitle2'
      weight='light'
      className={styles.subtitleContainer}>
      {i18n('Service for remote monitoring of vehicle data and proactive customer management')}
    </Typography>
  </SectionWrapper>
);