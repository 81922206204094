import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import { Fade } from 'react-reveal';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import SectionWrapper from '../../../../components/SectionWrapper';
import FooterItem from './FooterItem';
import styles from './styles.module.sass';
import Image from './Image';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo: imageSharp(fluid: {originalName: {eq: "Drive-Logo-Text-2x.png"}}) {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
        phone1: imageSharp(fluid: {
          originalName : {eq: "Fleet-Driver-1.jpg"}
        }) {
          fluid(maxWidth: 398, maxHeight: 772, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        phone2: imageSharp(fluid: {
          originalName : {eq: "Fleet-Driver-5.jpg"}
        }) {
          fluid(maxWidth: 398, maxHeight: 772, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        phone3: imageSharp(fluid: {
          originalName : {eq: "Fleet-Driver-2.jpg"}
        }) {
          fluid(maxWidth: 398, maxHeight: 772, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }        
        narrow: imageSharp(fluid: {
          originalName : {eq: "Main-Driver-App-Narrow.jpg"}
        }) {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }       
      }
    `}
    render={({ logo, phone1, phone2, phone3, narrow }) => (
      <SectionWrapper>
        <Grid item container className={
          clsx(
            styles.titleContainer,
            styles.productNameContainer
          )}>
          <div className={styles.logoContainer}>
            <Img imgStyle={{ objectFit: 'contain' }} 
              objectFit='contain'
              fluid={logo.fluid}
              alt='OBI+ Fleet'/>
          </div>
        </Grid>
        <Grid item container xs={12} md={12} xl={12}
          alignContent='center'
          justifyContent='center'
          className={styles.container}>
          <Grid item xs={12} sm={12} md={12} className={clsx(
              styles.imageContainer,
              styles.imageContainerNarrow
            )}>
            <Fade bottom>
              <Img objectFit='contain'
                className={styles.image}
                fluid={narrow.fluid} />
            </Fade>
          </Grid>
          <Image fluid={phone1.fluid}/>
          <Image fluid={phone2.fluid}
            delay={250}/>
          <Image fluid={phone3.fluid}
            delay={500}/>
        </Grid>
        <Grid container className={styles.footerContainer}
          alignContent='center'
          justifyContent='flex-end'>
          <FooterItem text={i18n('A mobile app for every driver that gives them a good overview')}/>
          <FooterItem text={i18n('Convenient contact with their preferred workshop')}/>
          <FooterItem text={i18n('Automatic logging and categorization of trips')}/>
        </Grid>
      </SectionWrapper>
    )}/>
);