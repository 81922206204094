import React from 'react';
import Page from '../views/Dealership/Features';

export default ({location}) => <Page location={location} seo={{
  title: "A unique automotive dealership management solution",
  lang: 'en',
  description: 'OBI+ offers a unique connected car solution for automotive dealerships - a service for remote monitoring of vehicle data and proactive customer management.',
  meta: [{
    name: 'keywords',
    content: 'Dealership management'
  }]
}}/>